<template>
  <NuxtI18nLink
    :to="gameLink"
    :class="{ mobile }"
    class="last-bet"
    data-t="last-bet"
    :title="bet.gameName"
  >
    <div class="game">
      <StIcon
        v-if="categoryIcon"
        :svg="categoryIcon"
        class="category-icon"
        size="24"
      />
      <StIcon v-else name="casino" class="category-icon" />
      <div class="game-info">
        <div>
          <div class="game-name">
            {{ bet.gameName }}
          </div>
          <div v-if="categoryName" class="category-name">
            {{ categoryName }}
          </div>
        </div>
      </div>
    </div>
    <!-- mobile off -->
    <template v-if="!mobile">
      <div class="nickname">
        <span v-if="bet.userNickname">{{ bet.userNickname }}</span>
        <span v-else>
          <StIcon size="16" name="anonymous-solid" class="anonymous" />
          {{ t('lastBets.hiddenUser') }}</span
        >
      </div>
      <div class="date">
        {{ date }}
      </div>
      <div class="bet-amount">
        <div class="crypto-amount">
          {{ cryptoBetsAmount }}
          <StIcon :name="currencyIcon" :size="16" />
        </div>
        <div class="fiat-amount">{{ fiatBetsAmount }}</div>
      </div>
      <div class="bet-rate" :class="betResultClass">{{ formattedRate }}</div>
    </template>

    <div class="bet-payoff">
      <div class="crypto-amount" :class="betResultClass">
        {{ cryptoAmount }}
        <StIcon :name="currencyIcon" :size="16" />
      </div>
      <div class="fiat-amount">{{ fiatBetResult }}</div>
    </div>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCasinoBet } from '@st/profile/composables'
import type { CasinoLastBet } from '../types'

const props = withDefaults(
  defineProps<{
    bet: CasinoLastBet
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)

const { bet } = toRefs(props)
const { t } = useI18n()

const {
  categoryIcon,
  categoryName,
  date,
  currencyIcon,
  cryptoBetsAmount,
  fiatBetsAmount,
  formattedRate,
  betResultClass,
  cryptoAmount,
  fiatBetResult,
  gameLink,
} = useCasinoBet(bet)
</script>

<style scoped>
.last-bet {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-300);
  align-items: center;

  padding: var(--spacing-100) var(--spacing-150);

  text-decoration: none;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);

  transition: background-color 0.2s;

  > * {
    flex: 10 1 0;
    min-width: 100px;
  }
}

.game {
  display: flex;
  flex-grow: 15;
  gap: var(--spacing-150);
  align-items: center;

  color: var(--text-secondary);
}

.category-icon {
  flex-shrink: 0;
}

.game-name {
  overflow: hidden;

  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: 0.1s color;
}

.game-info {
  flex-shrink: 1;
  min-width: 0;
}

.category-name {
  font: var(--desktop-text-xs-medium);
  color: rgb(255 57 93 / 100%);
}

.bet-id {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.bet-id-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bet-id-copy {
  flex-shrink: 1;
  min-width: 0;
}

.date {
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.crypto-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);
  text-align: right;
  text-decoration: none;

  &.positive {
    color: var(--text-success);
  }

  &.negative {
    color: var(--text-tertiary);
  }
}

.fiat-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: right;
}

.bet-amount,
.bet-payoff {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bet-rate {
  flex-grow: 5;
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
  text-align: right;

  &.positive {
    color: var(--text-success);
  }
}

.anonymous {
  margin-right: var(--spacing-075);
  background-color: var(--text-secondary);
}

.nickname {
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  span {
    display: flex;
    align-items: center;
  }
}

.mobile.last-bet {
  padding: 7px var(--spacing-150); /* stylelint-disable-line */

  .game-name,
  .crypto-amount {
    margin-bottom: var(--spacing-050);
    font: var(--mobile-text-medium);
  }

  .category-name,
  .fiat-amount {
    font: var(--mobile-caption-1-regular);
  }
}

.last-bet:hover {
  background-color: var(--background-tertiary);

  .game-name {
    color: var(--text-link);
  }
}
</style>
